import React from 'react';
import {
    CircularProgressbar,
    buildStyles
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import AnimatedProgressProvider from "../layout/AnimatedProgressProvider";

import AOS from 'aos';
import html from '../assets/images/html.png';
import css from '../assets/images/css.png';
import js from '../assets/images/js.png';
import php from '../assets/images/php.png';
import laravel from '../assets/images/laravel.png';
import october from '../assets/images/october.png';
import symfony from '../assets/images/symfony.png';
import sylius from '../assets/images/sylius.png';
import react from '../assets/images/react.png';
import jenkins from '../assets/images/jenkins.png';
import java from '../assets/images/java.png';

AOS.init();


const hero = () => {
    return (
        <div className="v-center">
            <div className="container">
                <div className="row">
                    <div className="col-md-12 text-center">
                        <h2 data-aos="fade-up"
                            data-aos-delay="0"
                            data-aos-duration="1000"
                            data-aos-easing="ease"
                            data-aos-once="true">About us</h2>
                        <div className="space-border"
                             data-aos="fade-up"
                             data-aos-delay="20"
                             data-aos-duration="1000"
                             data-aos-easing="ease"
                             data-aos-once="true"
                        ></div>
                    </div>
                    <div className="col-md-8 text-center m-auto"
                         data-aos="fade-up"
                         data-aos-delay="60"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <p>
                            {process.env.REACT_APP_NAME}, a leading provider of top-quality web development services
                            with a strong focus in UI/UX design.
                            Our company has established a reputation for delivering innovative and effective solutions
                            that meet the needs of businesses and organizations of all sizes.
                        </p>
                        <p>
                            At {process.env.REACT_APP_NAME}, we believe in the power of technology to transform the way
                            people live, work, and connect.
                            That's why we're dedicated to providing our clients with cutting-edge web development
                            services that are designed to meet their specific needs and help them achieve their goals.
                            {process.env.REACT_APP_NAME}, is committed to delivering top-quality web development
                            services that meet the unique needs of each client.
                            Whether you need a new website built from scratch, an existing site redesigned, or a custom
                            web application developed, {process.env.REACT_APP_NAME} has the skills and experience to get
                            the job done right.
                        </p>
                        <p>
                            With a passion for technology and a commitment to delivering outstanding
                            results, {process.env.REACT_APP_NAME} works closely with clients to understand their
                            specific needs and requirements. {process.env.REACT_APP_NAME} goal is to develop solutions
                            that are tailored to meet their goals and help them achieve their objectives.
                        </p>
                        <p>
                            If you're looking for a reliable and trusted web developer to bring your ideas to life, look
                            no further than {process.env.REACT_APP_NAME}. Contact us today to learn more about
                            how {process.env.REACT_APP_NAME} can help you achieve your goals."
                        </p>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="300"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={85}>
                            {value => {
                                return (
                                    <img src={html} alt="html"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">HTML</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="400"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={85}>
                            {value => {
                                return (
                                    <img src={css} alt="css"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">CSS</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="500"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={85}>
                            {value => {
                                return (
                                    <img src={js} alt="js"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">JS</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="600"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={php} alt="php"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">PHP</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="300"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={laravel} alt="laravel"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">Laravel</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="400"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={october} alt="october"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">October CMS</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="500"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={symfony} alt="symfony"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">Symfony</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="600"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={sylius} alt="sylius"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">Sylius</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="300"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={react} alt="react"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">React JS</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="400"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={jenkins} alt="jenkins"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">Jenkins</h4>
                    </div>
                    <div className="col-lg-3 p-5 text-center"
                         data-aos="fade"
                         data-aos-delay="500"
                         data-aos-duration="1000"
                         data-aos-easing="ease"
                         data-aos-once="true"
                    >
                        <AnimatedProgressProvider
                            valueStart={0}
                            valueEnd={95}>
                            {value => {
                                const roundedValue = Math.round(value);
                                return (
                                    <img src={java} alt="java"/>
                                );
                            }}
                        </AnimatedProgressProvider>
                        <h4 className="mt-2">Java</h4>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default hero;