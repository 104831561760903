import React from 'react';
import AOS from 'aos';

AOS.init();

const whatido = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 text-center"
                     data-aos="fade-up"
                     data-aos-delay="0"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <h2>What We Do</h2>
                    <div className="space-border"></div>
                </div>
            </div>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-lg-4  mb-5"
                     data-aos="fade"
                     data-aos-delay="300"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_genius id-color-2"></i>
                            <div className="text">
                                <h3>Website Development</h3>
                                We specialize in building custom websites from the ground up, designed to meet your
                                specific needs and goals. Whether you need a simple brochure site or a complex
                                e-commerce platform, we have the skills and experience to deliver the results you need.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  mb-5"
                     data-aos="fade"
                     data-aos-delay="400"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_tools id-color-2"></i>
                            <div className="text">
                                <h3>Custom Web Applications</h3>
                                Whether you need a custom tool to streamline your business processes or a unique
                                solution to meet your specific needs, we can develop custom web applications to meet
                                your requirements.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  mb-5"
                     data-aos="fade"
                     data-aos-delay="500"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_bag_alt id-color-2"></i>
                            <div className="text">
                                <h3>E-commerce Solutions</h3>
                                We can help you set up an online store and integrate with popular payment gateways,
                                allowing you to start selling your products and services online with ease.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4  mb-5"
                     data-aos="fade"
                     data-aos-delay="500"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_laptop id-color-2"></i>
                            <div className="text">
                                <h3>Web Hosting Services</h3>
                                We offer space on our servers for clients to store their website files and data,
                                allowing them to launch their websites and make them accessible on the internet.
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-4  mb-5"
                     data-aos="fade"
                     data-aos-delay="500"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_piechart id-color-2"></i>
                            <div className="text">
                                <h3>Domain management solutions</h3>
                                We provide domain registration, renewal, and management services to help clients manage their domain names and keep them up-to-date.
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-lg-4  mb-5"
                     data-aos="fade"
                     data-aos-delay="500"
                     data-aos-duration="1000"
                     data-aos-easing="ease"
                     data-aos-once="true"
                >
                    <div className="de_3d-box">
                        <div className="d-inner">
                            <i className="icon_cogs id-color-2"></i>
                            <div className="text">
                                <h3>Maintenance and Support</h3>
                                We offer ongoing maintenance and support to keep your website running smoothly and
                                ensure that it continues to meet your evolving needs.
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default whatido;