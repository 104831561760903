import React, {Component, useEffect} from "react";
import Slider from "react-slick";
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import {settings} from './settingsnew';
import {createGlobalStyle} from 'styled-components';
import AOS from 'aos';

AOS.init();


const GlobalStyles = createGlobalStyle`
  body.hide {
    overflow-y: hidden; /* Hide vertical scrollbar */
    overflow-x: hidden; /* Hide horizontal scrollbar */
  }
`;

class CustomSlide extends Component {
    render() {
        const {index, ...props} = this.props;
        return (
            <div {...props}></div>
        );
    }
}

const Blog = function () {
    /* lightbox1 */
    const [lighbx, setlighbx] = React.useState(false);
    const [blogIndex, setBlogIndex] = React.useState(0);
    const handleBtnClick = (index, e) => {
        setBlogIndex(index)
        setlighbx(!lighbx);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "hidden";

    };

    const handleBtnClickclose = () => {

        setlighbx(!lighbx);
        var x = document.getElementsByTagName("BODY")[0];
        x.style.overflow = "auto";
    };

    function handleEscKey(event) {
        if (event.key === 'Escape' && lighbx === true) {
            handleBtnClickclose();
        }
    }


    useEffect(() => {
        document.addEventListener('keydown', handleEscKey);
        return () => {
            document.removeEventListener('keydown', handleEscKey);
        };
    }, [lighbx]);
    return (
        <div className="container">
            <GlobalStyles/>
            <div className="spacer-single"></div>
            <div className="row">
                <div className="col-md-12 text-center mb-3">
                    <h2>Recent Blog</h2>
                    {/*<div className="space-border"></div>*/}
                </div>
            </div>
            <div className="row"
                 data-aos="fade-up"
                 data-aos-once="true"
            >
                <Slider {...settings}>
                    <CustomSlide className='itm' index={1}>
                        <div className="bloglist item">
                            <div className="post-content">
                                <div className="post-image">
                                    <div className="de_modal" onClick={() => handleBtnClick(1)}>
                                        <img alt="blogimg" src="./img/blog/1.jpg" className="grayscale"/>
                                    </div>
                                </div>
                                <div className="post-text">
                                    <h4 className="de_modal" onClick={() => handleBtnClick(1)}>How to Make
                                        Better User
                                        Interface</h4>
                                    <p>Making a better user interface (UI) requires a thoughtful and user-centered
                                        design approach ...</p>
                                </div>
                            </div>
                        </div>
                    </CustomSlide>

                    <CustomSlide className='itm' index={2}>
                        <div className="bloglist item">
                            <div className="post-content">
                                <div className="post-image">
                                    <div className="de_modal" onClick={() => handleBtnClick(2)}>
                                        <img alt="blogimg" src="./img/blog/2.jpg" className="grayscale"/>
                                    </div>
                                </div>
                                <div className="post-text">
                                    <h4 className="de_modal" onClick={() => handleBtnClick(2)}>10 Web Design Tips From
                                        Experts</h4>
                                    <p>Aspect of building a website that is not only aesthetically pleasing but also functional and user-friendly...</p>
                                </div>
                            </div>
                        </div>
                    </CustomSlide>

                    <CustomSlide className='itm' index={3}>
                        <div className="bloglist item">
                            <div className="post-content">
                                <div className="post-image">
                                    <div className="de_modal" onClick={() => handleBtnClick(3)}>
                                        <img alt="blogimg" src="./img/blog/3.jpg" className="grayscale"/>
                                    </div>
                                </div>
                                <div className="post-text">
                                    <h4 className="de_modal" onClick={() => handleBtnClick(3)}>The Importance of Web
                                        Design</h4>
                                    <p>Web design is an essential aspect of creating a website that is not only visually
                                        appealing b...</p>
                                </div>
                            </div>
                        </div>
                    </CustomSlide>
                </Slider>
            </div>

            {/* lightbox1 */}
            {lighbx && blogIndex === 1 && (
                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="blog-read">
                                        <img alt="blogimg" src="./img/blog/big.jpg" className="img-fullwidth rounded"/>

                                        <div className="post-info">
                                            <span className="post-date">Jan 16, 2023</span>
                                        </div>

                                        <h2>How to Make Better User Interface</h2>

                                        <div className="post-text">
                                            <p>Making a better user interface (UI) requires a thoughtful and
                                                user-centered design approach. Here are some key steps to help you make
                                                a better UI:</p>
                                            <ol>
                                                <li>Understand your users: Start by researching your target audience and
                                                    gaining a deep understanding of their needs, goals, and pain points.
                                                    This information will inform your design decisions and help you
                                                    create a UI that meets their needs.
                                                </li>
                                                <li>Conduct user testing: User testing is an important step in the
                                                    design process. Test your UI with real users to see how well it
                                                    works, identify areas for improvement, and validate your design
                                                    decisions.
                                                </li>
                                                <li>Make it simple and intuitive: The best UI designs are simple and
                                                    intuitive, making it easy for users to understand how to use your
                                                    website or application. Keep your design clean and uncluttered, and
                                                    use clear, concise language to guide users through the process.

                                                </li>
                                                <li>Focus on consistency: Consistency is key in UI design. Make sure
                                                    that your UI elements, such as buttons, icons, and forms, are
                                                    consistent across your website or application. This helps users
                                                    build mental models and navigate your site with ease.
                                                </li>
                                                <li>Use appropriate feedback: Feedback is an important part of the UI.
                                                    Use appropriate feedback mechanisms, such as animations, sounds, or
                                                    notifications, to help users understand what's happening and provide
                                                    context.
                                                </li>
                                                <li>Continuously iterate: UI design is a continuous process. Keep
                                                    testing, refining, and improving your UI over time to ensure that it
                                                    remains effective and user-friendly.
                                                </li>
                                            </ol>
                                            <p>By following these steps, you can create a UI that is simple, intuitive,
                                                and effective, helping your users achieve their goals and have a
                                                positive experience with your website or application.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {/* lightbox1 */}
            {lighbx && blogIndex === 2 && (

                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="blog-read">
                                        <img alt="blogimg" src="./img/blog/2.jpg" className="img-fullwidth rounded"/>

                                        <div className="post-info">
                                            <span className="post-date">Sept 16, 2022</span>
                                        </div>

                                        <h2>10 Web Design Tips From Experts</h2>

                                        <div className="post-text">
                                            <p>Web design is an essential aspect of building a website that is not only
                                                aesthetically pleasing but also functional and user-friendly. Here are
                                                ten tips from experts in the field of web design that can help you
                                                create an effective and memorable online presence.</p>
                                            <ol>
                                                <li>Keep it Simple: A simple and clean design can help improve the user
                                                    experience and make your site easier to navigate. Minimize the use
                                                    of flashy animations, graphics, and excessive colors.
                                                </li>
                                                <li>Make it Mobile-Friendly: With the majority of internet traffic now
                                                    coming from mobile devices, it is essential to make your website
                                                    mobile-responsive. Ensure that your site is optimized for all screen
                                                    sizes and loads quickly on mobile devices.
                                                </li>
                                                <li>Use High-Quality Images: Visually appealing images can grab the
                                                    attention of users and help convey your message. Use high-quality
                                                    images that are relevant to your content and optimized for the web
                                                    to improve loading times.

                                                </li>
                                                <li>Use Clear Typography: The right font and font size can greatly
                                                    impact readability and overall user experience. Use clear and
                                                    easy-to-read fonts and ensure that your text is well-spaced and
                                                    sized appropriately.
                                                </li>
                                                <li>Make Navigation Easy: A clear and intuitive navigation menu is
                                                    essential for a positive user experience. Make sure your navigation
                                                    menu is prominently displayed and organized in a logical manner.
                                                </li>
                                                <li>Use White Space: White space, also known as negative space, is the
                                                    area around elements on your website. It can help improve
                                                    readability and break up text, making your site look clean and
                                                    organized.
                                                </li>
                                                <li>Pay Attention to Load Times: Slow load times can drive away
                                                    potential visitors. Optimize your website for speed by compressing
                                                    images, minifying code, and reducing the number of plugins used.
                                                </li>
                                                <li>Incorporate Calls-to-Action: Calls-to-action are buttons or links
                                                    that encourage visitors to take a specific action on your website,
                                                    such as making a purchase or signing up for a newsletter. Make sure
                                                    your calls-to-action are clear and easy to find.
                                                </li>
                                                <li>Make Sure it is Accessible: Accessibility is important for users
                                                    with disabilities. Ensure your website is accessible by using clear
                                                    and concise language, providing alternative text for images, and
                                                    using proper headings and labels.
                                                </li>
                                                <li>Continuously Test and Improve: Your website is never finished and
                                                    should always be a work in progress. Continuously test and make
                                                    improvements to your site to ensure it is meeting the needs of your
                                                    users.
                                                </li>
                                            </ol>
                                            <p>By following these ten tips from web design experts, you can create a
                                                website that is both visually appealing and user-friendly. A
                                                well-designed website can help you establish your online presence,
                                                attract and retain visitors, and achieve your business goals.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
            {lighbx && blogIndex === 3 && (

                <div className="LightboxGal">
                    <div className="closeGal">
                        <button className="button-close" onClick={handleBtnClickclose}></button>
                    </div>
                    <div className="v-center w-100">
                        <div className="mainLightbox container">
                            <div className="row">
                                <div className="col-md-8 offset-md-2">
                                    <div className="blog-read">
                                        <img alt="blogimg" src="./img/blog/3.jpg" className="img-fullwidth rounded"/>

                                        <div className="post-info">
                                            <span className="post-date">Jan 6, 2023</span>
                                        </div>

                                        <h2>The Importance of Web Design </h2>

                                        <div className="post-text">
                                            <p>Web design is an essential aspect of creating a website that is not only
                                                visually appealing but also functional and user-friendly. There are
                                                several reasons why web design is important, including:</p>
                                            <ol>
                                                <li>Establishing a Professional Image: Your website is often the first
                                                    interaction a potential customer will have with your business. A
                                                    well-designed website can help establish a professional image and
                                                    set you apart from your competition.
                                                </li>
                                                <li>Improving User Experience: A website with a poor design can lead to
                                                    a frustrating user experience, causing visitors to leave your site
                                                    and potentially harm your reputation. A good web design can help
                                                    improve the overall user experience by making it easy to navigate
                                                    and find what they are looking for.
                                                </li>
                                                <li>Boosting SEO: Search engines favor websites with a clean,
                                                    well-structured design and user-friendly content. A well-designed
                                                    website can help improve your search engine rankings and attract
                                                    more organic traffic.

                                                </li>
                                                <li>Facilitating Conversions: A good web design can help improve the
                                                    conversion rate of your website by making it easier for visitors to
                                                    take action, whether it's making a purchase, filling out a form, or
                                                    signing up for a newsletter.
                                                </li>
                                                <li>Building Trust: A professional and well-designed website can help
                                                    build trust with visitors and establish credibility for your
                                                    business. This is especially important for online businesses where
                                                    customers may have concerns about security and reliability.
                                                </li>
                                                <li>Supporting Marketing Efforts: A well-designed website can support
                                                    your marketing efforts by providing a platform to showcase your
                                                    products and services, engage with your customers, and drive traffic
                                                    and conversions.
                                                </li>
                                            </ol>
                                            <p>In conclusion, web design is important because it can greatly impact the
                                                success of your website and your overall online presence. A
                                                well-designed website can help establish a professional image, improve
                                                the user experience, boost your search engine rankings, facilitate
                                                conversions, build trust, and support your marketing efforts.</p>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
}

export default Blog;