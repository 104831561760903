import React from 'react';
import ReactDOM from 'react-dom/client';
import "./assets/aos.css";
import "./assets/animated.css";
import '../node_modules/font-awesome/css/font-awesome.min.css'; 
import '../node_modules/elegant-icons/style.css';
import '../node_modules/et-line/style.css';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import '../node_modules/bootstrap/dist/js/bootstrap.js';
import './assets/style.scss';
import App from './main';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <App />
);
